function buildDescription() {
    return `${this.price.card}₽ или $${this.price.stripe}`;
}

const week = {
    price: {
        card: '149',
        stripe: '1.49',
    },
    fancyName: 'Недельная подписка',
    identifier: 'week',
};

week.getFancyPrice = buildDescription.bind(week);

const month = {
    price: {
        card: '299',
        stripe: '2.99',
    },
    fancyName: 'Месячная подписка',
    identifier: 'month',
};

month.getFancyPrice = buildDescription.bind(month);

const year = {
    price: {
        card: '999',
        stripe: '11.99',
    },
    fancyName: 'Годовая подписка',
    identifier: 'year',
};

year.getFancyPrice = buildDescription.bind(year);

const vip = {
    price: {
        card: '4999',
        stripe: '69',
    },
    fancyName: 'VIP',
    identifier: 'vip',
};

vip.getFancyPrice = buildDescription.bind(vip);

export const anonrubotPremiums = [week, month, year, vip];