import React from 'react';
import '../apps/App.css';
import {Outlet, useLocation} from "react-router-dom";
import {useI18n} from "../i18n/use-i18n";

export const Wrapper = () => {
    const location = useLocation();
    const {translate} = useI18n();
    return (
        <div className={'App'}>
            <nav className={'Navigator-nav'}>
                <a href={process.env.REACT_APP_PARENT_URL} className={'Navigator-a'}>
                    {translate('nav-home-page')}
                </a>
                <a href={'/'}
                   className={`Navigator-a ${location.pathname === '/' && 'active'}`}>
                    {translate('nav-payment-page')}
                </a>
            </nav>
            <Outlet/>
        </div>
    );
};
