import AnonRuBot from "../apps/AnonRuBot";
import Project from "../constants/project";
import Chatbot from "../apps/Chatbot";

function App() {
    switch (process.env.REACT_APP_PROJECT) {
        case Project.CHATBOT:
            return <Chatbot/>;
        case Project.ANONRUBOT:
            return <AnonRuBot/>
        default:
            return <AnonRuBot/>
    }
}


export default App;
