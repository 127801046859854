const card = {
    identifier: 'unit_pay_cards',
    title: 'card-payment',
};

const stripe = {
    identifier: 'stripe_cards',
    title: 'stripe-payment'
}

const cryptobot = {
    identifier: 'cryptobot',
    title: 'cryptobot-payment'
}

const xenditGeneral = {
    identifier: 'xendit_general',
    title: 'xendit-general-payment'
}

const xenditBankTransfer = {
    identifier: 'xendit_bank_transfer',
    title: 'xendit-bank-transfer-payment',
    extra: 'xendit-bank-transfer-extra'
}

const xenditAlfamart = {
    identifier: 'xendit_alfamart',
    title: 'xendit-alfamart-payment',
    extra: 'xendit-bank-alfamart-extra'
}


export const paymentMethodsMap = {
    ru: [card, stripe, cryptobot],
    en: [stripe, cryptobot, xenditGeneral, xenditBankTransfer, xenditAlfamart],
    id: [stripe, cryptobot, xenditGeneral, xenditBankTransfer, xenditAlfamart],
};
