import {
    AccountValidationState,
    mapValidationStateToCSS,
    mapValidationStateToTitle
} from "../constants/accountValidationStates";
import {PERSIST_USER_ID_KEY} from "../constants/commonConstants";
import ClipLoader from "react-spinners/ClipLoader";
import {useI18n} from "../i18n/use-i18n";

const UserIdInput = ({
                         validationState,
                         setAccountValidationState,
                         setUserId,
                         emptyUserIdError,
                         validateAccountAndUpdateState,
                         userId,
                     }) => {
    const {translate} = useI18n();
    return <div className={'App-input-user-id-container'}>
        <input
            className={
                `App-input-user-id
                                ${emptyUserIdError && AccountValidationState.CORRECT_ID !== validationState && 'invalid'} 
                                ${AccountValidationState.NONE !== validationState && 'extra-padding'}`
            }
            type="text"
            name="userid"
            placeholder={'544407461'}
            value={userId}
            onChange={(e) => {
                setAccountValidationState(AccountValidationState.NONE);
                setUserId(e.target.value?.trim());
                // If user removes input's value then remove persisted uid
                if (e.target.value?.trim() === "") {
                    localStorage.removeItem(PERSIST_USER_ID_KEY);
                }
            }}
            onFocus={(event) => {
                // Wrapping select() in setTimeout to make it work in Safari
                // https://stackoverflow.com/questions/54229359/why-does-select-not-work-on-safari-with-reactjs
                setTimeout(() => event.target.select(), 0);
            }}
            onBlur={() => {
                if (validationState !== AccountValidationState.NONE) {
                    // If validation state already exists, no need to validate again
                    return;
                }

                if (userId.length !== 0) {
                    setAccountValidationState(AccountValidationState.VALIDATING);
                    validateAccountAndUpdateState(userId);
                } else {
                    setAccountValidationState(AccountValidationState.NONE);
                }
            }}
        />
        {validationState !== AccountValidationState.NONE &&
            <span
                className={`App-account-validating-state ${mapValidationStateToCSS[validationState]}`}>
                            {validationState === AccountValidationState.VALIDATING && <ClipLoader
                                size={15}
                                color={'#6D84E4'}
                                cssOverride={{marginRight: 5}}
                            />}
                {translate(mapValidationStateToTitle[validationState])}
                        </span>}
    </div>
}

export default UserIdInput;