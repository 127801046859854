function buildDescription() {
    return `${this.currency}${this.price}`;
}

const threeDaysId = {
    price: '9000',
    fancyName: 'Setiap 3 hari',
    currency: 'IDR ',
    identifier: 'three-days_id',
};

threeDaysId.getFancyPrice = buildDescription.bind(threeDaysId);

const week = {
    price: '1.99',
    fancyName: 'Weekly premium',
    currency: '$',
    identifier: 'week',
};

week.getFancyPrice = buildDescription.bind(week);

const weekId = {
    price: '12 000',
    fancyName: 'Setiap minggu',
    currency: 'IDR ',
    identifier: 'week_id',
};

weekId.getFancyPrice = buildDescription.bind(weekId);

const month = {
    price: '3.99',
    fancyName: 'Monthly premium',
    currency: '$',
    identifier: 'month',
};

month.getFancyPrice = buildDescription.bind(month);

const monthId = {
    price: '21 000',
    fancyName: 'Setiap bulan',
    currency: 'IDR ',
    identifier: 'month_id',
};

monthId.getFancyPrice = buildDescription.bind(monthId);

const year = {
    price: '19.99',
    fancyName: 'Annual premium',
    currency: '$',
    identifier: 'year',
};

year.getFancyPrice = buildDescription.bind(year);

const yearId = {
    price: '87 000',
    fancyName: 'Setiap 12 bulan',
    currency: 'IDR ',
    identifier: 'year_id',
};

yearId.getFancyPrice = buildDescription.bind(yearId);

const vip = {
    price: '79.99',
    fancyName: 'VIP',
    currency: '$',
    identifier: 'vip',
};

vip.getFancyPrice = buildDescription.bind(vip);

const vipId = {
    price: '349 000',
    fancyName: 'VIP',
    currency: 'IDR ',
    identifier: 'vip',
};

vipId.getFancyPrice = buildDescription.bind(vipId);

export const chatbotPremiums = {
    en: [week, month, year, vip],
    id: [threeDaysId, weekId, monthId, yearId, vipId],
};